


















import { EClientType } from '@WorkPlatform/common/const';
import { appForward } from '@WorkPlatform/service/flow-center';
import { Component, Vue, Prop } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-item',
  components: {
    AppImage: () => import('./app-image.vue'),
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) details!: any;

  @workPlatformModule.State('recentIds') recentIds: any;

  @workPlatformModule.Action('setRecentIds') setRecentIds: any;

  @workPlatformModule.Action('setOnlineApps') setOnlineApps: any;

  clickHandler (item: any) {
    appForward(item, EClientType.MOBILE, item.homepages.mobileHomepage);
  }

  setRecentApp (id: string) {
    const ids: string | null = this.recentIds;
    let idsArr: any[] = [];
    if (Array.isArray(ids)) {
      idsArr = ids.filter((item: any) => item !== id);
    }
    idsArr.unshift(id);
    this.setRecentIds(idsArr);
  }
}
